import React from "react";
// import ReactPlayer from "react-player";
import { ChromeIcon } from "./components/ChromeIcon";

function App() {
  return (
    <div className="">
      {/* App bar */}
      <div className="px-3 absolute flex justify-center py-8 w-full">
        <div className="max-w-7xl px-8 flex-1">
          <div className="flex">
            <div className="flex-1">
              <div className="text-4xl text-yellow-400 font-['Gagalin']">
                YAGA
              </div>
            </div>
            <a
              className="justify-center flex items-center gap-2 rounded-lg border border-gray-400 p-2 cursor-pointer hover:bg-gray-600"
              href="https://chrome.google.com/webstore/detail/yaga/gdehljhgmbnmccikneadilnphpakmbhb?hl=en-US"
            >
              <div style={{ height: "20px", width: "20px" }}>
                <ChromeIcon />
              </div>
              <div className="text-white">Chrome Store</div>
            </a>
          </div>
        </div>
      </div>
      {/* Main page */}
      <section className="flex justify-center pt-16 h-screen font-['Gotham']">
        <div className="flex-1 sm:max-w-xl flex py-8">
          <div className="flex-1 flex flex-col px-3 items-center">
            <div>
              <h1 className="text-4xl font-semibold text-white leading-snug text-center">
                Yet Another GPT Application,
                <br />
                an AI Assistant for the Web
              </h1>
            </div>
            <div className="my-6">
              <p className=" text-white text-center leading-relaxed">
                YAGA is a powerful and user-friendly chrome extension that makes
                your reading and writing online more efficient. It leverages
                APIs that run the popular ChatGPT application to provide you
                with a GPT prompt management tool that helps you save time and
                be more productive. Whether you're a professional or a casual
                user, YAGA is an essential tool for anyone looking to optimize
                their web browsing experience.
              </p>
            </div>
            <div className="py-6 w-full flex justify-center">
              {/* TODO Video placement */}
              <div className="bg-white rounded-xl max-w-[524px] flex items-center justify-center flex-1 relative aspect-video">
                {/* <ReactPlayer url="https://www.youtube.com/watch?v=ysz5S6PUM-U" /> */}
                <img
                  src={"american-conversion.png"}
                  alt="demo"
                  className="h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
